
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['editAvailabilityClick'],
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
