<template>
  <div class="availability-exception-picker">
    <div class="weekday-picker-container">
      <CalendarDateTitle class="date-title" :date="selectedDay" />
      <WeekdayPicker
        mode="date"
        :selected="[selectedDay]"
        :highlightToday="true"
        :multiSelect="false"
        :disablePastDates="true"
        :selectConfirmation="areExceptionChangesMade"
        :disabled="submitPending"
        @update:selected="onSelectedDayChange"
        @selectAttempt="onSelectAttempt"
      />
    </div>
    <div class="time-slot-picker-container">
      <TimeSlotPicker
        v-if="!submitPending && !isLoading"
        :slots="timeSlots"
        :selected="selectedTimeSlots"
        :newSelected="newSelectedSlots"
        :newDeselected="newDeselectedSlots"
        @update:selected="onSelectedTimeSlotsChange"
      />
      <div class="progress-container" v-if="submitPending || isLoading">
        <ProgressSpinner />
      </div>
    </div>
    <div class="save-button-container flex-centered">
      <Button
        :class="['save-button', { 'p-button-primary': canSave }]"
        :label="t('save')"
        :disabled="!canSave"
        @click="onAvailabilityExceptionSave"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useAppStore } from '@bd/agent/store'
import { AddAgentAvailabilityExceptionDto, TimeSlot } from '@bd/api'
import {
  CalendarDateTitle,
  TimeSlotPicker,
  useLoadableResourceDataState,
  WeekdayPicker,
  WeekdaySelectType,
  CALENDAR_NAVIGATION_DEBOUNCE,
} from '@bd/components'
import { getLocalIsoDate, LocalIsoDate, sameArraysContent } from '@bd/helpers'
import { useDebounceFn } from '@vueuse/core'
import ProgressSpinner from 'primevue/progressspinner'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { AvailabilityMessages } from './Availability'

export default defineComponent({
  components: {
    WeekdayPicker,
    TimeSlotPicker,
    CalendarDateTitle,
    ProgressSpinner,
  },
  props: {
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const toast = useToast()

    const selectedDay = ref<LocalIsoDate>(getLocalIsoDate(new Date()))
    const submitPending = ref(false)
    const { isLoading, resource: exception } = useLoadableResourceDataState(
      computed(() => store.state.availability?.availabilityException),
    )

    const selectedTimeSlots = ref<TimeSlot.TimeSlotID[]>([])
    const exceptionTimeSlotIds = computed(() =>
      (exception.value?.timeSlots ?? []).map((slot) => slot.timeSlotId),
    )
    const newSelectedSlots = computed(() => {
      return exceptionTimeSlotIds.value.length
        ? selectedTimeSlots.value.filter(
            (slot) => !exceptionTimeSlotIds.value.includes(slot),
          )
        : []
    })
    const newDeselectedSlots = computed(() => {
      return exceptionTimeSlotIds.value.filter(
        (slot) => !selectedTimeSlots.value.includes(slot),
      )
    })

    const areExceptionChangesMade = computed(() => {
      return !sameArraysContent(
        exceptionTimeSlotIds.value,
        selectedTimeSlots.value,
      )
    })

    const canSave = computed(
      () =>
        !submitPending.value &&
        !isLoading.value &&
        areExceptionChangesMade.value,
    )

    const fetchExceptions = async () => {
      await store.dispatch(
        'availability/availabilityException',
        selectedDay.value,
      )
      selectedTimeSlots.value = exceptionTimeSlotIds.value
    }

    const debounceFetchExceptions = useDebounceFn(
      fetchExceptions,
      CALENDAR_NAVIGATION_DEBOUNCE,
    )

    const onSelectedDayChange = (
      selectedDays: LocalIsoDate[],
      selectType: WeekdaySelectType,
    ) => {
      selectedDay.value = selectedDays[0]
      toast.removeAllGroups()

      if (selectType === 'Direct') {
        fetchExceptions()
        return
      }
      debounceFetchExceptions()
    }

    const onSelectAttempt = () => {
      toast.add(AvailabilityMessages.selectAttempt)
    }

    const onSelectedTimeSlotsChange = (timeSlots: TimeSlot.TimeSlotID[]) => {
      selectedTimeSlots.value = timeSlots
    }

    const onAvailabilityExceptionSave = async () => {
      submitPending.value = true
      const payload: AddAgentAvailabilityExceptionDto = {
        date: selectedDay.value,
        timeSlotIds: selectedTimeSlots.value,
      }
      try {
        await store.dispatch('availability/saveAvailabilityException', payload)
        toast.add(AvailabilityMessages.exceptionSaveSuccess)
        await fetchExceptions()
      } catch (err) {
        toast.add(AvailabilityMessages.exceptionSaveError)
      } finally {
        submitPending.value = false
      }
    }

    fetchExceptions()

    return {
      t,
      selectedDay,
      selectedTimeSlots,
      submitPending,
      isLoading,
      areExceptionChangesMade,
      newDeselectedSlots,
      canSave,
      newSelectedSlots,
      onSelectedDayChange,
      onSelectAttempt,
      onAvailabilityExceptionSave,
      onSelectedTimeSlotsChange,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './availability';

.availability-exception-picker {
  @include base-container();
}

.weekday-picker-container {
  padding: 0 1rem 1rem 1rem;
  @include breakpoint-down(md) {
    padding: 1rem;
  }
}

.date-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 0 1rem 0;
}

.time-slot-picker-container {
  height: 300px;
  overflow-y: auto;
  padding: 0.25rem 1rem;
  @include breakpoint-down(md) {
    height: 100%;
  }
}
</style>
