<template>
  <div class="calendar-empty-state">
    <h1 class="title h-1">{{ t('calendar.availability.page_title') }}</h1>
    <p class="description body-bg">
      {{ t('calendar.empty_state.description') }}
    </p>
    <img :src="require('@bd/assets/images/calendar-empty-state.svg')" />
    <Button
      class="p-button-primary"
      :label="t('calendar.empty_state.action')"
      @click="$emit('editAvailabilityClick')"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['editAvailabilityClick'],
  setup() {
    const { t } = useI18n()
    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
.calendar-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;

  .description {
    text-align: center;
    max-width: 360px;
  }

  .title {
    margin-bottom: 1.5rem;
    @include breakpoint-down(md) {
      margin-bottom: 0.75rem;
    }
  }

  .description {
    margin-bottom: 2rem;
    @include breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }

  img {
    width: 100%;
    max-width: 240px;
    height: auto;
    margin-bottom: 3rem;
    @include breakpoint-down(md) {
      margin-bottom: 1.5rem;
      max-width: 220px;
    }
  }

  button {
    padding: 0 1.5rem;
  }
}
</style>
