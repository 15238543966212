
import AvailabilityModal from '@bd/agent/components/Calendar/Availability/AvailabilityModal.vue'
import CalendarEmptyState from '@bd/agent/components/Calendar/CalendarEmptyState.vue'
import CalendarHost from '@bd/agent/components/Calendar/CalendarHost.vue'
import { useAppStore } from '@bd/agent/store'
import { PageContent, useResourceDataState } from '@bd/components'
import ProgressSpinner from 'primevue/progressspinner'
import { computed, defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    PageContent,
    CalendarHost,
    CalendarEmptyState,
    AvailabilityModal,
    ProgressSpinner,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()

    const availabilityModalVisible = ref(false)
    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])
    const timeSlotsPresent = computed(
      () => timeSlots.value && !!timeSlots.value.length,
    )
    const availabilityRules = computed(
      () => store.state.availability?.availabilityRules,
    )
    const { isLoading, isPresent, isEmpty } = useResourceDataState(
      availabilityRules,
      (res) => !!(res && res.length),
    )

    const openAvailabilityModal = () => {
      availabilityModalVisible.value = true
    }

    const closeAvailabilityModal = () => {
      availabilityModalVisible.value = false
    }

    return {
      t,
      availabilityRules,
      openAvailabilityModal,
      closeAvailabilityModal,
      availabilityModalVisible,
      timeSlots,
      timeSlotsPresent,
      isLoading,
      isPresent,
      isEmpty,
    }
  },
})
