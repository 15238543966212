
import { TimeSlot } from '@bd/api'
import { MobileHeader, useBreakpoints } from '@bd/components'
import Dialog from 'primevue/dialog'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import AvailabilityExceptionPicker from './AvailabilityExceptionPicker.vue'
import AvailabilityPicker from './AvailabilityPicker.vue'

type AvailabilityView = 'Base' | 'Exception'

export default defineComponent({
  components: {
    Dialog,
    MobileHeader,
    AvailabilityPicker,
    AvailabilityExceptionPicker,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
  },
  emits: ['update:visible'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const toast = useToast()

    const currentView = ref<AvailabilityView>('Base')
    const isExceptionView = computed(() => currentView.value === 'Exception')
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)

    const onModalVisibilityChange = ($event: boolean) => {
      emit('update:visible', $event)
      toast.removeAllGroups()
    }

    return {
      t,
      currentView,
      isDesktopSize,
      onModalVisibilityChange,
      isExceptionView,
    }
  },
})
