import { AgentAvailabilityRuleDto, SaveAgentAvailabilityRuleDto } from '@bd/api'
import { useAppStore } from '@bd/agent/store'
import { TimeSlot, WeekdayAvailability } from '@itcraft-bestdeal/api'
import { computed, ComputedRef, Ref } from 'vue'
import { TimeSlotID, TimeSlotState } from '@bd/api/common/types/time-slot'
import { i18n } from '@bd/agent/plugins/vue-i18n-next-plugin'
import { ToastAddParams, TOAST_CONFIG } from '@bd/components'
import { isAvailabilitySet } from '@bd/agent/store/modules/availability/getters-helpers'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

type WeekdayAvailabilityMap = {
  [key in TimeSlot.Weekday]: keyof WeekdayAvailability
}

// Weekday structure allowing to map between TimeSlot.Weekday and backend-specific weekday fields
const WeekdayMap: WeekdayAvailabilityMap = {
  [TimeSlot.Weekday.MON]: 'mondayAvailability',
  [TimeSlot.Weekday.TUE]: 'tuesdayAvailability',
  [TimeSlot.Weekday.WED]: 'wednesdayAvailability',
  [TimeSlot.Weekday.THU]: 'thursdayAvailability',
  [TimeSlot.Weekday.FRI]: 'fridayAvailability',
  [TimeSlot.Weekday.SAT]: 'saturdayAvailability',
  [TimeSlot.Weekday.SUN]: 'sundayAvailability',
}

type ToastMessages =
  | 'selectAttempt'
  | 'availabilitySaveSuccess'
  | 'availabilitySaveError'
  | 'exceptionSaveSuccess'
  | 'exceptionSaveError'

// All possible toast messages for availability setting process
export const AvailabilityMessages: {
  [key in ToastMessages]: ToastAddParams
} = {
  selectAttempt: {
    severity: 'info',
    summary: t('calendar.availability.changes_unsaved.title'),
    detail: t('calendar.availability.changes_unsaved.description'),
  },
  availabilitySaveSuccess: {
    severity: 'success',
    summary: t('calendar.availability.submit.success'),
    life: messageDuration.success,
  },
  availabilitySaveError: {
    severity: 'error',
    summary: t('calendar.availability.submit.error'),
    life: messageDuration.error,
  },
  exceptionSaveSuccess: {
    severity: 'success',
    summary: t('calendar.availability.exception.submit.success'),
    life: messageDuration.success,
  },
  exceptionSaveError: {
    severity: 'error',
    summary: t('calendar.availability.exception.submit.error'),
    life: messageDuration.error,
  },
}

/**
 * Map entire availability rules array to a simple array of time slot ids for currently selected weekday
 * @param availability Current availability rules state
 * @param selectedWeekday Currently selected weekday
 * @returns Array of time slot ids
 */
export const mapAvailabilityToSlotIds = (
  availability: AgentAvailabilityRuleDto[],
  selectedWeekday: TimeSlot.Weekday,
): TimeSlot.TimeSlotID[] => {
  return availability
    .filter((rule) => rule[WeekdayMap[selectedWeekday]])
    .map((rule) => rule.timeSlotDto.timeSlotId)
}

export const useAvailability = (
  allTimeSlots: Ref<TimeSlot.TimeSlotDto[]>,
  selectedWeekDay: Ref<TimeSlot.Weekday>,
) => {
  const store = useAppStore()

  // Current availability state
  const agentAvailability = computed(
    () => store.state.availability?.availabilityRules,
  )

  // Time slots that should be selected by default if no availability was set yet
  const defaultTimeSlots = computed(() =>
    allTimeSlots.value.map((slot) => slot.timeSlotId),
  )

  // Whether the availability was already set for the user
  const wasAvailabilityPreviouslySet = computed(() =>
    isAvailabilitySet(store.state.availability),
  )

  // List of time slot ids that are set as available
  const availableTimeSlotIds = computed<TimeSlotID[]>(() => {
    if (!wasAvailabilityPreviouslySet.value) {
      return defaultTimeSlots.value
    }
    return mapAvailabilityToSlotIds(
      agentAvailability.value!,
      selectedWeekDay.value,
    )
  })

  return {
    defaultTimeSlots,
    agentAvailability,
    wasAvailabilityPreviouslySet,
    availableTimeSlotIds,
  }
}

/**
 * Map entire fetched availability rules array to a correspoinding availability rules array for saving
 * @param availability Availability rules array (current state)
 * @returns Array of availability rules for saving (add/edit)
 */
export const mapAvailabilityForSave = (
  availability: AgentAvailabilityRuleDto[],
): SaveAgentAvailabilityRuleDto[] =>
  availability.map((rule) => {
    const { agentAvailabilityRuleId: _, timeSlotDto, ...rest } = rule
    return {
      timeSlotId: timeSlotDto.timeSlotId,
      ...rest,
    }
  })

/**
 * Fills an entire availability structure with adequate data based on input data.
 * In general - for a given time slot return an object of backend-specific weekday properties (e.g 'mondayAvailability')
 * and fill them with boolean flag dictating availability in that time slot.
 * 1. If 'skipSelectedWeekday' flag is set it will be omitted in the final result and the consumer should handle that weekday manually
 * 2. If 'shouldSaveEntireWeek' flag is set the current weekday will be propagated into the entire week,
 * otherwise all weekdays other than the currently selected one will be set to 'false'
 * @param timeSlot Current time slot
 * @param selectedWeekday Currently selected weekday
 * @param shouldSaveEntireWeek If the currently selected weekday's availability should be propagated into the entire week
 * @param skipSelectedWeekday If the currently selected weekday should be skipped in the final result (false by defualt)
 * @returns
 */
const fillWeekdaysWithAvailability = (
  timeSlot: TimeSlotState,
  selectedWeekday: TimeSlot.Weekday,
  shouldSaveEntireWeek: boolean,
  skipSelectedWeekday = false,
): { [key in keyof WeekdayAvailability]: boolean } => {
  return Object.values(WeekdayMap)
    .filter((val) =>
      skipSelectedWeekday ? val !== WeekdayMap[selectedWeekday] : true,
    )
    .reduce(
      (acc, curr) =>
        Object.assign(acc, {
          [curr]: shouldSaveEntireWeek ? timeSlot.selected : false,
        }),
      {} as WeekdayAvailability,
    )
}

/**
 * Map current time slots state to an entire availability rules array
 * @param currentAvailability Current availability state
 * @param availabilityTimeSlotsState Current time slots selection state
 * @param selectedWeekday Currently selected weekday
 * @param shouldSaveEntireWeek If the result should span an entire week or just the selected weekday
 * @returns Data ready to be saved as new availability rules
 */
export const mapTimeSlotsStateForSave = (
  currentAvailability: AgentAvailabilityRuleDto[],
  availabilityTimeSlotsState: TimeSlot.TimeSlotState[],
  selectedWeekday: TimeSlot.Weekday,
  shouldSaveEntireWeek: boolean,
): SaveAgentAvailabilityRuleDto[] => {
  const mappedCurrentAvailability = mapAvailabilityForSave(currentAvailability)
  const mappedSelectedAvailability = availabilityTimeSlotsState.map((slot) => {
    // Find availability entry in currently saved availability
    const foundCurrentAvailability = mappedCurrentAvailability.find(
      (el) => el.timeSlotId === slot.timeSlotId,
    )

    /* If an availability for the current time slot is found - update it's 'selected' flag
     * In an usual case the time slot should always be found as long as the initial availability was saved correctly
     * First-time correctly saved availability should send all time slots regardless of their 'selected' status
     */
    if (foundCurrentAvailability) {
      if (shouldSaveEntireWeek) {
        const weekdays = fillWeekdaysWithAvailability(
          slot,
          selectedWeekday,
          shouldSaveEntireWeek,
          false,
        )
        return {
          timeSlotId: slot.timeSlotId,
          ...weekdays,
        }
      }
      return {
        ...foundCurrentAvailability,
        [WeekdayMap[selectedWeekday]]: slot.selected,
      }
    }

    const restWeekdays = fillWeekdaysWithAvailability(
      slot,
      selectedWeekday,
      shouldSaveEntireWeek,
    )

    return {
      timeSlotId: slot.timeSlotId,
      ...restWeekdays,
      [WeekdayMap[selectedWeekday]]: slot.selected,
    }
  })

  return mappedSelectedAvailability
}

/**
 * Get the state of all time slots. State meaning their selection status.
 * @param allTimeSlots Time slots to extract the state from
 * @param selectedTimeSlotIds Time slot ids that are selected (As seen in TimeSlotPicker component)
 * @returns Array of state-described time slots
 */
export const getTimeSlotsState = (
  allTimeSlots: Ref<TimeSlot.TimeSlotDto[]>,
  selectedTimeSlotIds: Ref<TimeSlot.TimeSlotID[]>,
): ComputedRef<TimeSlot.TimeSlotState[]> => {
  return computed(() =>
    allTimeSlots.value.map((slot) => {
      return {
        selected: selectedTimeSlotIds.value.includes(slot.timeSlotId),
        timeSlotId: slot.timeSlotId,
      }
    }),
  )
}
