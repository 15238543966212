<template>
  <Dialog
    class="availability-modal"
    header="&nbsp;"
    :showHeader="isExceptionView || isDesktopSize"
    :visible="visible"
    :modal="true"
    @update:visible="onModalVisibilityChange"
  >
    <template #header>
      <h4 v-if="!isExceptionView" class="h-4 dialog-header">
        {{ t('calendar.availability.page_title') }}
      </h4>
      <div class="exception-header" v-if="isExceptionView">
        <Button
          icon="pi pi-chevron-left"
          class="back-btn d-md-flex p-button-rounded p-button-outlined"
          @click="currentView = 'Base'"
        />
        <h1 class="d-md-none h-1">
          {{ t('calendar.availability.exception_adding') }}
        </h1>
      </div>
    </template>

    <MobileHeader
      v-if="!isExceptionView"
      :headerLabel="t('calendar.availability.page_title')"
      :shouldNavigate="false"
      @navigateClick="$emit('update:visible', false)"
    />

    <div
      class="content-wrapper"
      :class="{ base: !isExceptionView, exception: isExceptionView }"
    >
      <h1 v-if="isExceptionView" class="d-none d-md-block h-1 text-center mb-3">
        {{ t('calendar.availability.exception_adding') }}
      </h1>
      <div class="picker-container">
        <AvailabilityPicker
          v-if="!isExceptionView"
          :timeSlots="timeSlots"
          @addExceptionClick="currentView = 'Exception'"
        />
        <AvailabilityExceptionPicker v-else :timeSlots="timeSlots" />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { TimeSlot } from '@bd/api'
import { MobileHeader, useBreakpoints } from '@bd/components'
import Dialog from 'primevue/dialog'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import AvailabilityExceptionPicker from './AvailabilityExceptionPicker.vue'
import AvailabilityPicker from './AvailabilityPicker.vue'

type AvailabilityView = 'Base' | 'Exception'

export default defineComponent({
  components: {
    Dialog,
    MobileHeader,
    AvailabilityPicker,
    AvailabilityExceptionPicker,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
  },
  emits: ['update:visible'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const toast = useToast()

    const currentView = ref<AvailabilityView>('Base')
    const isExceptionView = computed(() => currentView.value === 'Exception')
    const { width, sizes } = useBreakpoints()
    const isDesktopSize = computed(() => width.value > sizes.md)

    const onModalVisibilityChange = ($event: boolean) => {
      emit('update:visible', $event)
      toast.removeAllGroups()
    }

    return {
      t,
      currentView,
      isDesktopSize,
      onModalVisibilityChange,
      isExceptionView,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './availability';

:deep(.availability-modal) {
  min-width: 460px;
  @include breakpoint-down(md) {
    height: 100%;
  }
  @include breakpoint-up(md) {
    @include dialog-border-radius(6px);
  }
  @include mobile-fullscreen-dialog();

  .p-dialog-content {
    padding: 0;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .p-dialog-header {
    padding-bottom: 0;
  }

  .p-dialog-header-icons {
    margin-left: auto;
  }
}
.exception-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  h1 {
    margin-right: auto;
    padding: 0 1rem;
  }
}
.dialog-header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.back-btn {
  margin-right: auto;
  flex: 0 0 auto;
}
.content-wrapper {
  height: 100%;
  padding: 1rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @include breakpoint-down(md) {
    padding: 0;
  }
  &.base {
    @include breakpoint-down(md) {
      margin-top: $mobile-header-height;
    }
  }
}
.picker-container {
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.btn-border {
  border: 1px solid $athens-gray;
}
</style>
