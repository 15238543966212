<template>
  <div class="availability-picker">
    <div class="weekday-picker-container">
      <WeekdayPicker
        mode="weekday"
        :selected="[selectedDay]"
        :multiSelect="false"
        :selectConfirmation="selectConfirmation"
        :disabled="submitPending"
        @update:selected="onSelectedDayChange"
        @selectAttempt="onSelectAttempt"
      />
    </div>
    <div class="controls-container">
      <Button
        class="exception-button p-button-secondary flex-centered mr-2 mr-md-1 p-button-rounded"
        :disabled="submitPending"
        @click="$emit('addExceptionClick')"
      >
        <Svg class="edit-icon" :src="require('@bd/assets/icons/edit.svg')" />
        <div>{{ t('calendar.availability.add_exception') }}</div>
      </Button>
      <div class="checkbox-container flex-centered ml-2 mr-md-1">
        <Checkbox
          class="p-checkbox-primary mr-2"
          name="saveEntireWeek"
          v-model="shouldSaveEntireWeek"
          :binary="true"
          :disabled="submitPending"
        />
        <label class="checkbox-label" for="saveEntireWeek">{{
          t('calendar.availability.save_for_entire_week')
        }}</label>
      </div>
    </div>
    <div class="time-slot-picker-container">
      <TimeSlotPicker
        v-if="!submitPending"
        :slots="timeSlots"
        :selected="selectedAvailabilityTimeSlots"
        @update:selected="onSelectedTimeSlotsChange"
      />
      <div class="progress-container" v-else>
        <ProgressSpinner />
      </div>
    </div>
    <div class="save-button-container flex-centered">
      <Button
        :class="[
          'save-button p-button-rounded',
          { 'p-button-primary': canSave },
        ]"
        :label="saveLabel"
        :disabled="!canSave"
        @click="onAvailabilitySave"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useAppStore } from '@bd/agent/store'
import { SaveAgentAvailabilityRuleDto, TimeSlot } from '@bd/api'
import { Svg, TimeSlotPicker, WeekdayPicker } from '@bd/components'
import { sameArraysContent } from '@bd/helpers'
import Checkbox from 'primevue/checkbox'
import ProgressSpinner from 'primevue/progressspinner'
import { useToast } from 'primevue/usetoast'
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  AvailabilityMessages,
  getTimeSlotsState,
  mapTimeSlotsStateForSave,
  useAvailability,
} from './Availability'

export default defineComponent({
  components: {
    WeekdayPicker,
    TimeSlotPicker,
    Svg,
    Checkbox,
    ProgressSpinner,
  },
  props: {
    timeSlots: {
      type: Array as PropType<TimeSlot.TimeSlotDto[]>,
      required: true,
    },
  },
  emits: ['addExceptionClick'],
  setup(props) {
    const { timeSlots: allTimeSlots } = toRefs(props)
    const store = useAppStore()
    const { t } = useI18n()
    const toast = useToast()

    const selectedDay = ref<TimeSlot.Weekday>(TimeSlot.Weekday.MON)
    const submitPending = ref(false)

    const {
      defaultTimeSlots,
      agentAvailability,
      wasAvailabilityPreviouslySet,
      availableTimeSlotIds,
    } = useAvailability(allTimeSlots, selectedDay)

    const shouldSaveEntireWeek = ref(!wasAvailabilityPreviouslySet.value) // Set default value

    const selectedAvailabilityTimeSlots = ref(
      wasAvailabilityPreviouslySet.value
        ? availableTimeSlotIds.value
        : defaultTimeSlots.value,
    )

    const availabilityTimeSlotsState = getTimeSlotsState(
      allTimeSlots,
      selectedAvailabilityTimeSlots,
    )

    const saveLabel = computed(() =>
      t(
        `calendar.availability.${
          shouldSaveEntireWeek.value ? 'save_week' : 'save_day'
        }`,
      ),
    )

    // Is previously stored availability content the same as the selected availability
    const isSavedAvailabilitySameAsSelected = () => {
      return sameArraysContent(
        availableTimeSlotIds.value,
        selectedAvailabilityTimeSlots.value,
      )
    }

    // Were there changes made to the availability
    const areAvailabilityChangesMade = computed(() => {
      if (wasAvailabilityPreviouslySet.value) {
        return !isSavedAvailabilitySameAsSelected()
      }
      return true
    })

    // Should the confirmation toast show when attempting to navigate between weekdays
    const selectConfirmation = computed(() => {
      if (!wasAvailabilityPreviouslySet.value) {
        return !sameArraysContent(
          defaultTimeSlots.value,
          selectedAvailabilityTimeSlots.value,
        )
      }
      return !isSavedAvailabilitySameAsSelected()
    })

    // Is initially set availability not-empty (othewrise true on subsequent availability setting sessions)
    const isFirstAvailabilityNotEmpty = computed(
      () =>
        (!wasAvailabilityPreviouslySet.value &&
          !!selectedAvailabilityTimeSlots.value.length) ||
        wasAvailabilityPreviouslySet.value,
    )

    const canSave = computed(
      () =>
        !submitPending.value &&
        (areAvailabilityChangesMade.value || shouldSaveEntireWeek.value) &&
        isFirstAvailabilityNotEmpty.value,
    )

    const onSelectedDayChange = (selectedDays: TimeSlot.Weekday[]) => {
      selectedDay.value = selectedDays[0]
      selectedAvailabilityTimeSlots.value = availableTimeSlotIds.value
      toast.removeAllGroups()
    }

    const onSelectedTimeSlotsChange = (value: TimeSlot.TimeSlotID[]) => {
      selectedAvailabilityTimeSlots.value = value
    }

    const onSelectAttempt = () => {
      toast.add(AvailabilityMessages.selectAttempt)
    }

    const onAvailabilitySave = async (_: TimeSlot.TimeSlotID[]) => {
      submitPending.value = true
      const payload: SaveAgentAvailabilityRuleDto[] = mapTimeSlotsStateForSave(
        agentAvailability.value ?? [],
        availabilityTimeSlotsState.value,
        selectedDay.value,
        shouldSaveEntireWeek.value,
      )
      const actionName = `availability/${
        wasAvailabilityPreviouslySet.value
          ? 'editAvailability'
          : 'addAvailability'
      }`

      try {
        await store.dispatch(actionName, payload)
        shouldSaveEntireWeek.value = false
        toast.add(AvailabilityMessages.availabilitySaveSuccess)
        store.dispatch('availability/availabilityRules')
      } catch (err) {
        toast.add(AvailabilityMessages.availabilitySaveError)
      } finally {
        submitPending.value = false
      }
    }

    return {
      t,
      selectedDay,
      submitPending,
      selectedAvailabilityTimeSlots,
      shouldSaveEntireWeek,
      areAvailabilityChangesMade,
      canSave,
      saveLabel,
      selectConfirmation,
      onSelectedDayChange,
      onSelectAttempt,
      onSelectedTimeSlotsChange,
      onAvailabilitySave,
    }
  },
})
</script>

<style lang="scss" scoped>
@import './availability';

.availability-picker {
  @include base-container();
}

.weekday-picker-container {
  padding: 0.5rem 1rem;
  @include breakpoint-down(md) {
    background-color: $alabaster;
  }
}

.controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.25rem 2rem 0.25rem;
  @include breakpoint-down(md) {
    padding: 1rem 1rem;
  }
}
.exception-button {
  flex: 1;
  padding: 0 1rem;
  .edit-icon {
    @include uniform-size(1rem);
    margin-right: 1rem;
  }
}
:deep(.p-checkbox) {
  @include uniform-size(30px);
  .p-checkbox-box {
    border-radius: 8px;
    @include uniform-size(30px);
    .p-checkbox-icon {
      font-size: 1.2rem;
    }
  }
}
.checkbox-container {
  flex: 1;
}
.checkbox-label {
  color: $primary-color;
  font-weight: 500;
  text-align: center;
}
.time-slot-picker-container {
  height: 300px;
  overflow-y: auto;
  padding: 0.25rem 1rem;
  @include breakpoint-down(md) {
    height: 100%;
  }
}
</style>
