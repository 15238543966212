<template>
  <CalendarView
    v-model:selectedDay="selectedDay"
    v-model:navigatedDate="navigatedDate"
    :timeSlots="timeSlots"
    :todayCalendarEvents="todayCalendarEvents"
    :userType="UserType.Agent"
    :upcomingEventDates="upcomingEventDates"
    @update:selectedDay="selectedDayChange"
    @eventTileClick="eventTileClicked"
    @addEventClick="addEvent"
    @editAvailabilityClick="editAvailability"
  />
</template>

<script lang="ts">
import { useAppStore } from '@bd/agent/store'
import { UserCalendarEventDto } from '@bd/api/common'
import { AgentUserCalendarEventsParams } from '@bd/api/common/types/calendar'
import { CalendarView, useCalendarLocalState, UserType } from '@bd/components'
import { LocalIsoDate } from '@bd/helpers'
import { computed, defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    CalendarView,
  },
  emits: ['editAvailabilityClick', 'addEventClick'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const upcomingEventDates = computed(
      () => store.state.calendar?.upcomingEventDates,
    )

    const {
      selectedDay,
      navigatedDate,
      onSelectedDayChange,
    } = useCalendarLocalState(store.state.calendar?.selectedDate)

    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])
    const todayCalendarEvents = computed(
      () => store.state.calendar?.todayEvents ?? [],
    )

    const fetchTodayEvents = async () => {
      const params: AgentUserCalendarEventsParams = {
        date: selectedDay.value,
      }
      await store.dispatch('calendar/userCalendarEvents', params)
    }

    const selectedDayChange = (day: LocalIsoDate) => {
      if (onSelectedDayChange(day)) {
        store.commit('calendar/SET_SELECTED_DATE', day)
        fetchTodayEvents()
      }
    }

    const addEvent = () => {
      emit('addEventClick')
    }

    const editAvailability = () => {
      emit('editAvailabilityClick')
    }

    const eventTileClicked = (event: UserCalendarEventDto) => {
      router.push({
        path: `/calendar/event/${event.userCalendarEventId}`,
      })
    }

    const fetchUpcomingEventDates = async () => {
      await store.dispatch('calendar/getUpcomingEventDates')
    }

    onMounted(() => {
      fetchTodayEvents()
      fetchUpcomingEventDates()
    })

    return {
      t,
      timeSlots,
      selectedDay,
      selectedDayChange,
      todayCalendarEvents,
      navigatedDate,
      addEvent,
      editAvailability,
      eventTileClicked,
      UserType,
      upcomingEventDates,
    }
  },
})
</script>

<style lang="scss" scoped></style>
